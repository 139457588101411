import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  uploadIcon(payload) {
    return Api().post(`/v2/uploads?organization_id=${organization_id()}`, payload);
  },
  uploadExcel(payload, name) {
    return Api().post(`/${name}/excel/import?organization_id=${organization_id()}`, payload)
  }
};
