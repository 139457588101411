<template>
  <div>
    <span
      class="text-center table-edit-icon"
      @click="getPreview()"
      v-if="params.data.user_agreement != '-'"
    >
      <i class="text-center material-icons">get_app</i>
    </span>
    <h4>{{ params.data.user_agreement == "-" ? "-" : "" }}</h4>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalView' + params.data.user_id"
        title="User Details"
        :active.sync="popupActive"
      >
        <div class="vx-col w-full">
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Name:</p>
                <div class>
                  <h5>{{ userDetail.user_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Email:</p>
                <div class>
                  <h5 class="text-break">{{ userDetail.user_email }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Address:</p>
                <div class>
                  <h5>{{ userDetail.user_address }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Phone:</p>
                <div class>
                  <h5>{{ userDetail.user_mobile }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Gender:</p>
                <div class>
                  <h5>{{ userDetail.user_gender }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Date of Birth:</p>
                <div class>
                  <h5>{{ userDetail.user_dob || "-" }}</h5>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">City:</p>
                <div class>
                  <h5>{{ userDetail.city_name }}</h5>
                </div>
              </div>
            </div>
          </div>-->
        </div>
        <h3 class="text-center mb-4">-Employment Details-</h3>
        <div
          class="vx-col w-full border-bottom mb-6"
          v-for="(empDetails, epmIndex) in userEmpDetails"
          :key="epmIndex"
        >
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <p class="text-muted small mb-1">Company Name:</p>

              <div class>
                <h5>{{ empDetails.company_name }}</h5>
              </div>
            </div>

            <div class="col-6">
              <p class="text-muted small mb-1">Designation Name:</p>
              <div class>
                <h5>{{ empDetails.designation_name }}</h5>
              </div>
            </div>
          </div>
          <div class="mx-0 row">
            <div class="col-6">
              <p class="text-muted small mb-1">Department Name:</p>
              <div class>
                <h5>{{ empDetails.department_name }}</h5>
              </div>
            </div>

            <div class="col-6">
              <p class="text-muted small mb-1">Role Name:</p>
              <div class>
                <h5>{{ empDetails.role_name }}</h5>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import UserService from "@/services/UserService.js";
import { error } from "util";
import moment from "moment";
export default Vue.extend({
  data() {
    return {
      userDetail: [],
      params: null,
      singleUserId: "",
      userEmpDetails: [],
      popupActive: false
      //   project_id: ""
    };
  },
  components: {
    moment
  },

  beforeMount: function() {
    // this.value = this.params.data;
    // this.singleUserId = this.value.user_id;
    this.user_id = this.params.value;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.user_id
    );
    _id.setAttribute("style", "display: none;");
  },
  filters: {
    moment: function(date) {
      if (date == "" || date == null) {
        return "-";
      } else {
        return moment(date).format("DD-MM-YYYY");
      }
    }
  },

  methods: {
    getPreview: function() {
      //  this.popupActive = true;
      window.open(this.params.data.user_agreement);
      // UserService.getSingleUser(
      //   this.user_id,
      //   String(this.params.data.user_active) === "4" ? "archive" : undefined
      // )
      //   .then(response => {
      //     const { data } = response;
      //     if (!data.err) {
      //       this.userDetail = data.data;
      //       this.userEmpDetails = data.data.Employment;
      //     }
      //   })
      //   .catch(error => {
      //     console.log("error :", error);
      //   });
    }
  }
});
</script>

<style></style>
