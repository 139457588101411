<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- ADD USERS FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Organization">
          <form>
            <div class="row">
              <div class="col-6 mb-2">
                <h6>Organization Name*</h6>

                <vs-input
                  v-validate="'required|alpha_spaces'"
                  name="Name"
                  v-model="form.organization_name"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Name')"
                    >{{ errors.first("Name") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Mobile No.*</h6>
                <vs-input
                  v-validate="'required|numeric|max:14'"
                  name="Mobile Number"
                  v-model="form.organization_mobile"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Mobile Number')"
                    >{{ errors.first("Mobile Number") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Email*</h6>

                <vs-input
                  v-validate="'required|email'"
                  name="Email"
                  v-model="form.organization_email"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Email')"
                    >{{ errors.first("Email") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-5">
                <h6>Address*</h6>

                <vs-textarea
                  v-validate="'required'"
                  name="Address"
                  v-model="form.organization_address"
                  class="w-full mb-0"
                  rows="4"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Address')"
                    >{{ errors.first("Address") }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-6 mb-2">
                <h6>Organization Type*</h6>
                <multiselect
                  v-validate="'required'"
                  v-model="form.organization_type"
                  track-by="organization_type_id"
                  label="organization_type_name"
                  :options="organization_type_array"
                  name="Organization Type"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.organization_type_name }}
                  </template>
                </multiselect>
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Organization Type')"
                    >{{ errors.first("Organization Type") }}</span
                  >
                </p>
              </div>

              <div class="col-6 mb-4">
                <h6>Organization Logo</h6>
                <vue-dropzone
                  @vdropzone-error="vdropzoneError"
                  @vdropzone-complete="vdropzoneComplete"
                  @vdropzone-files-added="vdropzoneFilesAdded"
                  @vdropzone-success="vdropzoneSuccess"
                  ref="myVueDropzone"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptions"
                ></vue-dropzone>
                <vs-button
                  v-if="imageUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearImage()"
                  class="mr-3 feather icon-trash"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6 mb-2">
                <h6>Domain Name*</h6>

                <vs-input
                  v-validate="'required'"
                  name="text"
                  v-model="form.domainName"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('domainName')"
                    >{{ errors.first("domainName") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2">
                <h6>Primary Color*</h6>

                <vs-input
                  v-validate="'required'"
                  name="text"
                  v-model="form.primaryColor"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('primaryColor')"
                    >{{ errors.first("primaryColor") }}</span
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <h6>Host Name*</h6>

                <vs-input
                  v-validate="'required'"
                  name="text"
                  v-model="form.hostName"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('hostName')"
                    >{{ errors.first("hostName") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-4">
                <h6>Favicon</h6>
                <vue-dropzone
                  @vdropzone-error="vdropzoneErrorfav"
                  @vdropzone-complete="vdropzoneCompletefav"
                  @vdropzone-files-added="vdropzoneFilesAddedfav"
                  @vdropzone-success="vdropzoneSuccessfav"
                  ref="myVueDropzonefav"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptionsfav"
                ></vue-dropzone>
                <vs-button
                  v-if="imageUploadfav.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearImagefav()"
                  class="mr-3 feather icon-trash"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mb-8">
                <h6>Status*</h6>
                <!-- <div class="vx-col sm:w-2/3 w-full"> -->
                <vs-switch v-model="form.organization_active" />
                <!-- </div> -->
              </div>

              <div class="col-md-3 mb-8">
                <h6>Tally Active*</h6>
                <!-- <div class="vx-col sm:w-2/3 w-full"> -->
                <vs-switch v-model="form.tally_isActive" />
                <!-- </div> -->
              </div>
              <div class="col-md-3 mb-8">
                <h6>Advanced Cash Active*</h6>
                <!-- <div class="vx-col sm:w-2/3 w-full"> -->
                <vs-switch v-model="form.advance_isActive" />
                <!-- </div> -->
              </div>
              <div class="col-md-3 mb-8">
                <h6>Credit Note Active*</h6>
                <!-- <div class="vx-col sm:w-2/3 w-full"> -->
                <vs-switch v-model="form.creditNote_isActive" />
                <!-- </div> -->
              </div>
              <div class="col-md-3 mb-8">
                <h6>Digital Sign*</h6>
                <!-- <div class="vx-col sm:w-2/3 w-full"> -->
                <vs-switch v-model="form.digiSign_isActive" />
                <!-- </div> -->
              </div>
              <div class="col-md-3 mb-8">
                <h6>PO Active</h6>
                <vs-switch v-model="form.show_po" />
              </div>
            </div>

            <vs-button
              :disabled="submitStatus"
              type="filled"
              @click.prevent="submitForm"
              class="mt-5 mr-3"
              >Submit</vs-button
            >

            <vs-button
              color="warning"
              type="border"
              class
              @click.prevent="clearForm"
              >Reset</vs-button
            >
            <!-- <div class="vx-col">
              <div class="excel-btn-1">
                <vx-tooltip text="Template Excel" position="left">
                  <vs-button
                    type="border"
                    class="py-2"
                    @click.native="
                      onClickSampleDownload(excelurl + `/v2/users/excel/sample`)
                    "
                  >
                    <img
                      class="d-inline-flex"
                      src="@/assets/images/icons/excel.svg"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </vx-tooltip>
              </div>

              <div class="excel-btn-2">
                <vx-tooltip text="Import Excel" position="left">
                  <label for="import-file">
                    <input
                      ref="uploadExcelFile"
                      @change="uploadExcel($event)"
                      type="file"
                      name="import-file"
                      id="import-file"
                      class="d-none"
                    />

                    <div class="vs-component vs-button px-4 vs-button-primary vs-button-filled">
                      <span class="d-inline-flex pr-5 text-white">Import</span>
                      <img
                        class="d-inline-flex"
                        src="@/assets/images/icons/import-excel.svg"
                        height="20"
                        width="20"
                      />
                    </div>
                  </label>
                </vx-tooltip>
              </div>
            </div>-->
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrganizationService from "@/services/OrganizationService.js";
import RolesService from "@/services/userRolesService.js";
import companyService from "@/services/companyService.js";
import employeeService from "@/services/employeeService.js";
import departmentService from "@/services/departmentService.js";
import designationService from "@/services/designationService.js";
import uploadService from "@/services/uploadService.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";

import constant from "@/helper/constant.js";
const VxTour = () => import("@/components/VxTour.vue");
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    flatPickr,
    vueDropzone: vue2Dropzone,
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");
    this.dropzoneOptions["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsfav["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptions["headers"]["authorization"] = this.access_token;
    this.dropzoneOptionsfav["headers"]["authorization"] = this.access_token;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      excelurl: BASEURL,
      //  maxDate: new Date().fp_incr(-6600),
      config: {
        maxDate: new Date(),
        dateFormat: "d-m-Y",
      },
      file: [],
      organization_type_array: [
        {
          organization_type_name: "Kozo",
          organization_type_id: "0",
        },
        {
          organization_type_name: "Contract",
          organization_type_id: "1",
        },
        {
          organization_type_name: "Both",
          organization_type_id: "2",
        },
      ],
      companies: [],
      departments: [],
      designations: [],
      designation_value: [],
      department_value: [],
      company_value: [],
      role_value: [],
      dropzoneOptions: {
        url: BASEURL + "/v2/multipleUploads",
        ...constant.DEFAULT_IMAGE,
        acceptedFiles: "image/*",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      dropzoneOptionsfav: {
        url: BASEURL + "/v2/multipleUploads",
        ...constant.DEFAULT_IMAGE,
        acceptedFiles: "image/*",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      dropzoneOptionsFiles: {
        url: BASEURL + "/v2/uploads",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      imageUploadfav: {
        image_url: "",
        deleteButtonStatus: false,
      },
      fileUploadfav: {
        file_url: "",
        deleteButtonStatus: false,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      submitStatus: true,
      //userdata
      user_dob: "",
      organization_logo: "-",
      user_agreement: "-",
      form: {
        organization_name: "",
        organization_email: "",
        organization_address: "",
        // organization_logo: "NA1",
        organization_active: true,
        organization_mobile: "",
        organization_type: "",
        tally_isActive: true,
        advance_isActive: true,
        creditNote_isActive: true,
        digiSign_isActive: false,
        show_po: false,
        primaryColor: "",
        domainName: "",
        hostName: "",
      },
      User: "Select User",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      roles: [],
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        {
          text: "New York",
          value: "new-york",
        },
        {
          text: "Chicago",
          value: "chicago",
        },
        {
          text: "San Francisco",
          value: "san-francisco",
        },
        {
          text: "Boston",
          value: "boston",
        },
      ],
      columnDefs: [
        {
          headerName: "User Name",
          field: "firstname",
          width: 275,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: "Expense Head",
          field: "lastname",
          filter: true,
          width: 275,
        },
        {
          headerName: "Expense Category",
          field: "email",
          filter: true,
          width: 375,
          //   pinned: "left"
        },
        {
          headerName: "Amount (₹)",
          field: "company",
          filter: true,
          width: 200,
        },
        {
          headerName: "Expense Date",
          field: "city",
          filter: true,
          width: 200,
        },
        {
          headerName: "Status",
          field: "state",
          filter: true,
          width: 150,
        },
      ],
      contacts: contacts,
      // FORM DETAILS
      required: "",
      alpha: "",
      alpha_dash: "",
      alpha_num: "",
      numeric: "",
      alpha_spaces: "",
      email: "",
      url: "",
      min: "",
      max: "",
      password: "",
      confirm_password: "",
      date_format: "",
      between: "",
      credit_card: "",
      date_between: "",
      digits: "",
      select1: "",
      options1: [
        {
          text: "ABC Name",
          value: 1,
        },
        {
          text: "ABC Name",
          value: 2,
        },
        {
          text: "ABC Name",
          value: 3,
        },
        {
          text: "ABC Name",
          value: 4,
        },
        {
          text: "ABC Name",
          value: 5,
        },
      ],
      max_value: "",
      min_value: "",
      regex: "",
      organization_id: "",
      favicon: "-",
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      // if (val <= 576) {
      //   this.maxPageNumbers = 4;
      //   this.gridOptions.columnApi.setColumnPinned("email", null);
      // } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length - 3;
        if (newVal.department_id == "new") {
          len = len + 1;
        }

        if (newVal.designation_id == "new") {
          len = len + 1;
        }

        if (
          len <=
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    checkUserType() {
      return window.localStorage.getItem("UserType");
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function (event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      // console.log(that.excelurl + `/v2/users/import/excel?organization_id=${that.organization_id}`);
      axios
        .post(
          that.excelurl +
            `/v2/users/import/excel?organization_id=${that.organization_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              // title: "Duplicate entries have not been imported.",
              title: "Error!",
              text: data.error.message || "Error",
              // time:8000,
              time: 35000,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            eventBus.$emit("refreshorganizationTable", data);
          }
          that.$vs.loading.close();
          // that.$vs.notify({
          //   title: "Updated!",
          //   text: data.message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "success"
          // });
          eventBus.$emit("refreshorganizationTable", data);
        })
        .catch((error) => {
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";

          that.$vs.loading.close();
          that.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    clearImage: function () {
      this.imageUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzone.removeAllFiles();
      this.$refs.myVueDropzone.enable();
    },
    clearFile: function () {
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.$refs.myVueDropzoneFile.removeAllFiles();
    },
    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },

    vdropzoneSuccess: function (file, response) {
      this.organization_logo = response.data.image_path;
      this.imageUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzone.disable();
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    clearFilefav: function () {
      this.fileUploadfav.deleteButtonStatus = false;
      this.$refs.myVueDropzonefav.enable();
      this.$refs.myVueDropzonefav.removeAllFiles();
    },
    clearImagefav: function () {
      this.imageUploadfav.deleteButtonStatus = false;
      this.$refs.myVueDropzonefav.removeAllFiles();
      this.$refs.myVueDropzonefav.enable();
    },

    vdropzoneFilesAddedfav: function (file) {
      console.log("file 1 :", file);
    },

    vdropzoneSuccessfav: function (file, response) {
      this.favicon = response.data.image_path;
      this.imageUploadfav.deleteButtonStatus = true;
      this.$refs.myVueDropzonefav.disable();
    },
    vdropzoneCompletefav: function (response) {
      console.log("response :", response);
    },
    vdropzoneErrorfav: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    getCompanyList: function () {
      companyService
        .getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
            if (this.companies.length > 0) {
              // this.form.company_id = this.companies[0].company_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    // DEPARTMENT LIST
    getDepartmentList: function () {
      departmentService
        .getAllDepartments()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.departments = data.data;
            if (this.departments.length > 0) {
              // this.form.department_id = this.departments[0].department_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    //  DESIGNATION LIST
    getDesignationList: function () {
      designationService
        .getAllDesignations()
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.designations = data.data;
            if (this.designations.length > 0) {
              // this.form.designation_id = this.designations[0].designation_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getRolesList: function () {
      console.log("RolesService :", RolesService);
      RolesService.getAllUserRoles()
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.roles = data.data;
            if (this.roles.length > 0) {
              // this.form.role_id = this.roles[0].role_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    submitForm() {
      //   console.log("object");
      this.$validator.validateAll().then((result) => {
        //    console.log("result", result);
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          this.form.organization_active = this.form.organization_active ? 1 : 2;
          this.form.tally_isActive = this.form.tally_isActive ? 1 : 0;
          this.form.advance_isActive = this.form.advance_isActive ? 1 : 0;
          this.form.creditNote_isActive = this.form.creditNote_isActive ? 1 : 0;
          //digiSign_isActive
          this.form.digiSign_isActive = this.form.digiSign_isActive ? 1 : 0;
          this.form.show_po = this.form.show_po ? 1 : 0;
          this.form.organization_type =
            this.form.organization_type.organization_type_id;
          this.form["organization_logo"] = this.organization_logo;
          this.form["favicon"] = this.favicon;

          let payload = {
            ...this.form,
          };
          console.log(payload);

          OrganizationService.addOrganization(payload)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();
                this.clearImage();
                this.clearImagefav();
                //this.clearFile();
                setTimeout(() => {
                  eventBus.$emit("refreshorganizationTable", data);
                }, 1);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              // this.$vs.notify({
              //   title: "Error!",
              //   text: error.Message,
              //   iconPack: "feather",
              //   icon: "check_box",
              //   color: "warning"
              // });
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    clearForm: function () {
      this.user_dob = "";
      this.organization_logo = "-";
      this.favicon = "-";
      this.user_agreement = "-";
      this.form = {
        organization_name: "",
        organization_email: "",
        organization_address: "",
        // organization_logo: "NA1",
        organization_active: true,
        organization_mobile: "",
        organization_type: "",
        tally_isActive: true,
        advance_isActive: true,
        creditNote_isActive: true,
        digiSign_isActive: false,
        show_po: false,
        domainName: "",
        hostName: "",
        primaryColor: "",
      };

      this.designation_value = [];
      this.department_value = [];
      this.company_value = [];
      this.role_value = [];
      this.$validator.reset();
      this.clearImage();
      this.clearFile();
    },
  },
  mounted() {
    // this.getCompanyList();
    // this.getDepartmentList();
    // this.getDesignationList();
    // this.getRolesList();
    this.form.domainName = BASEURL;
    this.form.hostName = BASEURL;
    this.form.primaryColor = window.PrimaryColor;
    this.organization_id = localStorage.getItem("OrganizationID");
    this.gridApi = this.gridOptions.api;
  },
};
</script>
