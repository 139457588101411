import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addDesignation(payload) {
    return Api().post(`/v2/designation?organization_id=${organization_id()}`, payload);
  },
  editDesignation(payload, id) {
    return Api().put(`/v2/designation/${id}?organization_id=${organization_id()}`, payload);
  },
   getAllDesignationByCompany(company_id) {
     return Api().get(`/v2/designation/company/${company_id}?organization_id=${organization_id()}`);
   },

  getAllDesignations(filters, sort) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/designation?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/designation/page/${filters.page}?organization_id=${organization_id()}`);
    } else
      return Api().get(`/v2/designation?organization_id=${organization_id()}`);
  },

  getDesignation(payload, id) {
    return Api().get(`/v2/designation/${id}?organization_id=${organization_id()}`, payload);
  },
};
