export default {
  DEFAULT_IMAGE: {
    thumbnailWidth: 150,
    maxFilesize: 50,
    maxFiles: 50,
    acceptedFiles: "image/*",
  },

  DEFAULT_FILES: {
    thumbnailWidth: 150,
    maxFilesize: 50,
    maxFiles: 50,
    acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt",
  },
};
