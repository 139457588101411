import Api from "./Api.js";
// import {
//   database
// } from 'firebase';

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addCompany(payload) {
    return Api().post(
      `/v2/company?organization_id=${organization_id()}`,
      payload
    );
  },
  editCompany(payload, id) {
    return Api().put(
      `/v2/company/${id}?organization_id=${organization_id()}`,
      payload
    );
  },

  getAllCompanies(filters, sort) {
    if (filters && filters.hasOwnProperty("archive") && filters.archive) {
      return Api().get(
        `/v2/company?archive=1&organization_id=${organization_id()}`
      );
    } else if (filters && filters.hasOwnProperty("page")) {
      return Api().get(
        `/v2/company/page/${filters.page}?organization_id=${organization_id()}`
      );
    } else return Api().get(`/v2/company?organization_id=${organization_id()}`);
  },

  getCompany(id, archive) {
    if (!archive) {
      return Api().get(
        `/v2/company/${id}?organization_id=${organization_id()}`
      );
    } else {
      return Api().get(
        `/v2/company/${id}?${archive}&organization_id=${organization_id()}`
      );
    }
  }
};
