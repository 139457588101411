import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addEmployee(payload) {
    return Api().post(`/v2/employment?organization_id=${organization_id()}`, payload);
  },
  editEmployee(payload, id) {
    return Api().put(`/v2/employment/${id}?organization_id=${organization_id()}`, payload);
  },

  getAllEmployees(filters, sort) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/employment/page/${filters.page}?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/employment?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page'))
      return Api().get(`/v2/employment/page/${filters.page}?organization_id=${organization_id()}`);
    else return Api().get(`/v2/employment?organization_id=${organization_id()}`);
  },


  getEmployee(id, archive) {
    if (!archive) {
      return Api().get(`/v2/employment/${id}?organization_id=${organization_id()}`);
    } else {
      return Api().get(`/v2/employment/${id}?${archive}&organization_id=${organization_id()}`);
    }
  }
};
